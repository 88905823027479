import React from "react"

import Layout from "../components/layout"
import { PrivacyPolicy } from '../components/privacyPolicy/index' 
import SEO from '../components/seo'


const TorunPrivacyPolicyPage = () => (
  <Layout torun>
    <SEO
      title='Torun polityka prywatnosci'
      description='Polityka prywatnosci'
      url='https://bodymedgroup.pl/torun-polityka-prywatnosci'
    />

    <PrivacyPolicy/>
  </Layout>
)

export default TorunPrivacyPolicyPage
